import { FilterList } from "@mui/icons-material";
import { Box, Button, Container, Grid, TablePagination } from "@mui/material";
import { FC, useReducer, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CardTutor } from "../../components/card-tutor/card-tutor.component";
import { tutores } from "../../constants";
import { Tutor } from "../../types";
import { SearchTutorProvider } from "./search-tutor.provider";
import {
  SEARCH_TUTOR_INITIAL_STATE,
  searchTutorReducer,
} from "./search-tutor.reducer";
import { Group } from "./search-tutor.styles";

export const SearchTutorPage: FC = () => {
  const navegar = useNavigate();

  const [state, dispatch] = useReducer(
    searchTutorReducer,
    SEARCH_TUTOR_INITIAL_STATE
  );

  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const abrirModalDeAgendamento = (tutor: Tutor) => {
    dispatch({ type: "SELECIONAR_TUTOR", tutor });
    navegar("/buscar-tutor/agendar");
  };

  return (
    <SearchTutorProvider state={state} dispatch={dispatch}>
      <Container>
        <Box
          display="flex"
          justifyContent="flex-end"
          gap="8px"
          mb="1em"
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Group>
            <TablePagination
              component="div"
              count={100}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[8, 16, 24, 32]}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} de ${
                  count !== -1 ? count : `mais de ${to}`
                }`;
              }}
              labelRowsPerPage="Tutores por página"
            />
          </Group>
          <Button
            startIcon={<FilterList />}
            variant="outlined"
            onClick={() => navegar("/buscar-tutor/filtrar")}
          >
            Filtrar
          </Button>
        </Box>

        <Grid container spacing="16px" mb="1em">
          {tutores.map((tutor) => (
            <Grid
              key={tutor.id}
              item
              xs={12}
              sm={6}
              md={3}
              data-aos="flip-left"
              data-aos-duration="2000"
            >
              <CardTutor
                tutor={tutor}
                agendarTutoria={abrirModalDeAgendamento}
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      <Outlet />
    </SearchTutorProvider>
  );
};
