import axios from "axios";
import { adicionarTokenInterceptor } from "../common/add-token-interceptor";
import { obterDados } from "../common/axios-methods";
import { BASE_URL } from "../common/base-url";
import { CriarTutoradoEntrada, CriarTutoradoSaida } from "./tutee.types";

const tutoradoAPI = axios.create({
    baseURL: `${BASE_URL}/tutorado`
})

tutoradoAPI.interceptors.request.use(adicionarTokenInterceptor)

export const criarTutorado = (entrada: CriarTutoradoEntrada): Promise<CriarTutoradoSaida> => {
    return tutoradoAPI.post('/', entrada).then(obterDados)
}