import { styled } from "@mui/material";

export const ModalBody = styled("div")`
  width: 100%;
  max-width: 800px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 32px;
  background-color: #f8f8ff;

  border-radius: 10px;
`;
