import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    background: { default: "#F8F8FF" },
    primary: {
      main: "#8C62E0",
    },
    secondary: {
      main: "#0073E6",
    },
  },
  components: {
    MuiButton: { styleOverrides: { contained: { color: "#F8F8FF" } } },
    MuiAvatar: {
      styleOverrides: {
        root: { backgroundColor: "#BFBFBF" },
        fallback: {
          fill: "#595959",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "#595959",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
