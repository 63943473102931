import { ChevronLeft } from "@mui/icons-material";
import { Button, CircularProgress, Stack } from "@mui/material";
import { FC } from "react";

export type BackAndContinueProps = {
  voltar: () => void;
  continuar: () => void;
  carregando?: boolean;
};

export const BackAndContinue: FC<BackAndContinueProps> = ({
  voltar,
  continuar,
  carregando,
}) => {
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      data-aos="fade-right"
      data-aos-duration="1000"
      data-aos-delay="1000"
    >
      <Button startIcon={<ChevronLeft />} variant="text" onClick={voltar}>
        Voltar
      </Button>
      <Button
        size="large"
        variant="contained"
        onClick={continuar}
        sx={{ width: 150 }}
        disabled={carregando}
      >
        {carregando ? <CircularProgress size={20} /> : "Continuar"}
      </Button>
    </Stack>
  );
};
