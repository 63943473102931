import { z } from "zod";

export const senha = z.string().refine((value) => {
    const minCharacter = value.length > 8
    // Verifica se a senha contém pelo menos uma letra maiúscula
    const hasUpperCase = /[A-Z]/.test(value);
    // Verifica se a senha contém pelo menos uma letra minúscula
    const hasLowerCase = /[a-z]/.test(value);
    // Verifica se a senha contém pelo menos um caracter especial
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    return hasUpperCase && hasLowerCase && hasSpecialChar && minCharacter;
}, {
    message: "A senha deve conter pelo menos 8 caracteres, sendo uma letra maiúscula, uma letra minúscula e um caracter especial.",
});
