import GitHubIcon from "@mui/icons-material/GitHub";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { IconButton, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { FC, Fragment } from "react";
import { Logo } from "../logo/logo.component";
import { FooterStyles } from "./footer.styles";
import { useTranslation } from "react-i18next";
import PopupState, { bindTrigger } from "material-ui-popup-state";
import { Language } from "@mui/icons-material";
import { LanguageMenu } from "../language-menu/language-menu.component";

export const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <FooterStyles.Background>
      <Container>
        <Grid
          container
          spacing={"16px"}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid container item xs={6} md={6} alignItems="flex-start">
            <Logo />
          </Grid>
          <Grid item xs={6} md={6}>
            <Stack justifyContent="flex-end" direction="row">
              <PopupState variant="popover" popupId="language-menu">
                {(popupState) => (
                  <Fragment>
                    <IconButton {...bindTrigger(popupState)}>
                      <Language />
                    </IconButton>
                    <LanguageMenu popupState={popupState} />
                  </Fragment>
                )}
              </PopupState>
              <IconButton href="https://github.com/TutorAllBR" target="_blank">
                <GitHubIcon></GitHubIcon>
              </IconButton>
              <IconButton
                href="https://tutorallbr.blogspot.com/"
                target="_blank"
              >
                <RssFeedIcon></RssFeedIcon>
              </IconButton>
              <IconButton
                href="https://www.youtube.com/@TutorAllBR"
                target="_blank"
              >
                <YouTubeIcon></YouTubeIcon>
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2">
              IFSP - Campus São Paulo - Projeto Integrado I e II - 2024
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              sx={{
                justifyContent: {
                  sm: "flex-start",
                  md: "flex-end",
                },
              }}
              direction="row"
              spacing={1}
            >
              <FooterStyles.Link href="#">
                {t("comum.termos-de-uso")}
              </FooterStyles.Link>
              <FooterStyles.Link href="#">
                {t("comum.politica-de-privacidade")}
              </FooterStyles.Link>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </FooterStyles.Background>
  );
};
