import { Interesse } from "../../api/interest/interest.types";
import { Disciplina } from "../../api/subject/subject.types";
import { Perfil } from "../../types";

export enum Etapa {
    DADOS_DE_ACESSO = "DADOS_DE_ACESSO",
    DADOS_EDUCACIONAIS = "DADOS_EDUCACIONAIS",
    DADOS_DE_PERFIL = "DADOS_DE_PERFIL",
    DADOS_DO_TUTOR = "DADOS_DO_TUTOR",
}

export type SignUpEstado = {
    etapa: Etapa;
    campos: {
        fotoBase64: string;
        fotoFile: File | null;
        historicoBase64: string;
        historicoFile: File | null;
        nome: string;
        email: string;
        senha: string;
        instituicaoDeEnsino: number | null;
        curso: number | null;
        prontuario: string;
        perfil: Perfil;
        disciplinas: Disciplina[];
        interesses: Interesse[];
        justificativa: string;
    };
    erros: {
        fotoBase64: string | null;
        historicoBase64: string | null;
        nome: string | null;
        email: string | null;
        senha: string | null;
        instituicaoDeEnsino: string | null;
        curso: string | null;
        prontuario: string | null;
    };
};


export type AcaoDefinirNome = {
    type: "DEFINIR_NOME";
    value: string;
};

export type AcaoDefinirFotoBase64 = {
    type: "DEFINIR_FOTO_BASE64";
    value: string;
};

export type AcaoDefinirFotoFile = {
    type: "DEFINIR_FOTO_FILE";
    value: File;
};

export type AcaoDefinirHistoricoBase64 = {
    type: "DEFINIR_HISTORICO_BASE64";
    value: string;
};

export type AcaoDefinirHistoricoFile = {
    type: "DEFINIR_HISTORICO_FILE";
    value: File;
};

export type AcaoDefinirEmail = {
    type: "DEFINIR_EMAIL";
    value: string;
};

export type AcaoDefinirSenha = {
    type: "DEFINIR_SENHA";
    value: string;
};

export type AcaoDefinirInstituicao = {
    type: "DEFINIR_INSTITUICAO";
    value: number;
};

export type AcaoDefinirCurso = {
    type: "DEFINIR_CURSO";
    value: number;
};

export type AcaoDefinirProntuario = {
    type: "DEFINIR_PRONTUARIO";
    value: string;
};

export type AcaoDefinirEtapa = {
    type: "DEFINIR_ETAPA";
    etapa: Etapa;
};

export type AcaoDefinirPerfil = {
    type: "DEFINIR_PERFIL";
    perfil: Perfil;
};

export type AcaoDefinirDisciplinas = {
    type: "DEFINIR_DISCIPLINAS";
    disciplinas: Disciplina[];
};

export type AcaoDefinirInteresses = {
    type: "DEFINIR_INTERESSES";
    interesses: Interesse[];
};

export type AcaoDefinirJustificativa = {
    type: "DEFINIR_JUSTIFICATIVA";
    justificativa: string;
};

export type AcaoDefinirErros = {
    type: "DEFINIR_ERROS";
    erros: {
        foto?: string;
        nome?: string;
        email?: string;
        senha?: string;
        instituicaoDeEnsino?: string;
        curso?: string;
        prontuario?: string;
    };
};

export type SignUpAcoes =
    | AcaoDefinirNome
    | AcaoDefinirFotoBase64
    | AcaoDefinirFotoFile
    | AcaoDefinirEmail
    | AcaoDefinirSenha
    | AcaoDefinirInstituicao
    | AcaoDefinirCurso
    | AcaoDefinirProntuario
    | AcaoDefinirEtapa
    | AcaoDefinirPerfil
    | AcaoDefinirDisciplinas
    | AcaoDefinirInteresses
    | AcaoDefinirJustificativa
    | AcaoDefinirErros
    | AcaoDefinirHistoricoBase64
    | AcaoDefinirHistoricoFile


export type LineAndTextProps = {
    text: string;
};