import { z } from "zod";

export const obterMensagensDeErro = (error: z.ZodError) => {
    const erros = Object.entries(error.formErrors.fieldErrors);

    type Mensagem = { [key: string]: string };

    const mensagens = erros.reduce<Mensagem>((errors, error) => {
        const campo = error[0];
        const mensagem = error[1]?.shift();

        if (campo && mensagem) {
            errors[campo] = mensagem;
        }

        return errors;
    }, {});

    return mensagens
}