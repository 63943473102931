import { styled } from "@mui/material";

const Background = styled("footer")`
  margin-top: 24px;
  background-color: #bfbfbf;
  width: 100%;
  padding: 20px;
`;

const Link = styled("a")`
  color: #595959;
  text-decoration: none;
`;

export const FooterStyles = {
    Background,
    Link
}