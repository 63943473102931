import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './locale/en.json';
import ptbr from './locale/pt-br.json';

i18n.use(initReactI18next).init({
    resources: {
        en,
        "pt-BR": ptbr
    },
    lng: "en",
    interpolation: {
        escapeValue: false
    }
});

export default i18n;