import { Dispatch, createContext } from "react";
import { SearchTutorAcoes, SearchTutorState } from "./search-tutor.types";

type SearchTutorContextValue = {
  state: SearchTutorState;
  dispatch: Dispatch<SearchTutorAcoes>;
};

export const SearchTutorContext = createContext<SearchTutorContextValue>(
  {} as any
);
