import Typography from "@mui/material/Typography";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import tutorallLogo from "../../assets/images/tutorall-logo.png";
import { LogoStyles } from "./logo.styles";

export const Logo: FC = () => {
  const navigate = useNavigate();

  const navegarParaHome = () => {
    navigate("/");
  };

  return (
    <LogoStyles.Background onClick={navegarParaHome}>
      <LogoStyles.Image src={tutorallLogo} />
      <Typography
        variant="h6"
        noWrap
        component="a"
        href="#"
        sx={{
          fontFamily: "MuseoModerno",
          fontWeight: 550,
          letterSpacing: ".1rem",
          color: "#8C62E0",
          textDecoration: "none",
        }}
      >
        TutorAll
      </Typography>
    </LogoStyles.Background>
  );
};
