import { Box, Button, Typography } from "@mui/material";
import lottie from "lottie-web";
import { FC, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import SuccessAnimation from "../../assets/animations/success-animation.json";
import { Perfil } from "../../types";

export const SignUpSuccessView: FC = () => {
  const navegar = useNavigate();
  const localizacao = useLocation();

  const perfil = localizacao.state?.perfil;

  const acessarPlataforma = () => {
    if (perfil === Perfil.TUTORADO) {
      navegar("/buscar-tutor", { replace: true });
    } else if (perfil === Perfil.TUTOR) {
      navegar("/painel-de-controle", { replace: true });
    }
  };

  useEffect(() => {
    const animationItem = lottie.loadAnimation({
      container: document.querySelector(".animation")!,
      loop: false,
      autoplay: true,
      animationData: SuccessAnimation,
    });

    return () => {
      animationItem.destroy();
    };
  }, []);

  if (perfil === undefined) {
    return <Navigate to="/cadastrar" replace={true} />;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="calc(100vh - 64px)"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        gap="16px"
      >
        <Box className="animation" width="200px" />
        <Typography variant="h5">Cadastro finalizado com sucesso!</Typography>
        <Typography variant="body1" color="text.secondary">
          Você está pronto para experimentar tutoria para todos com TutorAll
        </Typography>
        <Button size="large" variant="contained" onClick={acessarPlataforma}>
          Acessar plataforma
        </Button>
      </Box>
    </Box>
  );
};
