import { AssignmentTurnedIn, MeetingRoom, Menu } from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { Navbar } from "../../components/navbar/navbar.component";
import { MainLayoutStyles } from "./main.styles";

export const MainLayout: FC = () => {
  const navegar = useNavigate();
  const [menuAberto, setMenuAberto] = useState(false);

  const { t } = useTranslation();

  const abrirModal = () => {
    navegar("/auth/login");

    if (menuAberto) {
      setMenuAberto(false);
    }
  };

  const AbrirOuFecharMenu = () => {
    setMenuAberto((valorAnterior) => !valorAnterior);
  };

  const navegarParaTelaDeCadastro = () => {
    navegar("/cadastrar");
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Navbar
        esquerda={
          <MainLayoutStyles.Left>
            <IconButton onClick={AbrirOuFecharMenu}>
              <Menu />
            </IconButton>
          </MainLayoutStyles.Left>
        }
        direita={
          <MainLayoutStyles.Right>
            <Button variant="outlined" onClick={abrirModal}>
              {t("comum.entrar")}
            </Button>
            <Button variant="contained" onClick={navegarParaTelaDeCadastro}>
              {t("comum.cadastre-se")}
            </Button>
          </MainLayoutStyles.Right>
        }
      />

      <Drawer open={menuAberto} onClose={AbrirOuFecharMenu}>
        <Box sx={{ width: 250 }} role="presentation">
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={abrirModal}>
                <ListItemIcon>
                  <MeetingRoom />
                </ListItemIcon>
                <ListItemText primary={t("comum.entrar")} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={navegarParaTelaDeCadastro}>
                <ListItemIcon>
                  <AssignmentTurnedIn />
                </ListItemIcon>
                <ListItemText primary={t("comum.cadastre-se")} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Outlet />
    </Box>
  );
};
