import { Dispatch, createContext } from "react";
import { SignUpAcoes, SignUpEstado } from "./sign-up.types";

export type SignUpContextoValor = {
    state: SignUpEstado | null
    dispatch: Dispatch<SignUpAcoes> | null
}

export const SignUpContexto = createContext<SignUpContextoValor>({
    state: null,
    dispatch: null
})