import { styled } from "@mui/material";

const Left = styled('div')`
    margin-right: 8px;

    ${({ theme }) => theme.breakpoints.up('md')} {
        display: none;
    }
`

const Right = styled('div')`
`


export const TutoradoStyles = {
    Left,
    Right
}