import { AppBarProps } from "@mui/material";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { FC, ReactElement } from "react";
import { Logo } from "../logo/logo.component";
import { NavbarStyles } from "./navbar.styles";

export type NavbarProps = AppBarProps & {
  esquerda?: ReactElement;
  direita?: ReactElement;
};

export const Navbar: FC<NavbarProps> = ({ esquerda, direita, ...rest }) => {
  return (
    <NavbarStyles.Background {...rest} position="static" elevation={1}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {esquerda}
          <Logo />
          {direita}
        </Toolbar>
      </Container>
    </NavbarStyles.Background>
  );
};
