import { Radio, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { CustomRadioStyles } from "./custom-radio.styles";

export type CustomRadioProps = PropsWithChildren<{
  selecionado?: boolean;
  titulo: string;
  icon: JSX.Element;
  onClick: () => void;
}>;

export const CustomRadio: FC<CustomRadioProps> = ({
  selecionado,
  titulo,
  icon,
  onClick,
  children,
  ...rest
}) => {
  return (
    <CustomRadioStyles.Background onClick={onClick} {...rest}>
      <Radio checked={selecionado} />
      <CustomRadioStyles.IconWrapper>{icon}</CustomRadioStyles.IconWrapper>
      <CustomRadioStyles.Content>
        <Typography variant="h6">{titulo}</Typography>
        {children}
      </CustomRadioStyles.Content>
    </CustomRadioStyles.Background>
  );
};
