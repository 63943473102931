
export const obterTokenDeAcesso = (): string | null => {
    return localStorage.getItem('access')
}

export const obterTokenDeAtualizacao = (): string | null => {
    return localStorage.getItem('refresh')
}

export const salvarTokens = (access: string, refresh: string): void => {
    localStorage.setItem('access', access)
    localStorage.setItem('refresh', refresh)
}

export const removerTokens = () => {
    localStorage.clear()
}