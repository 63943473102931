import { createBrowserRouter } from "react-router-dom";
import { AuthModalForgotPasswordView } from "./modals/auth/auth-forgot-password.view";
import { AuthModalLoginView } from "./modals/auth/auth-login.view";
import { AuthModalResetPasswordView } from "./modals/auth/auth-reset-password.view";
import { AuthModalResetSuccess } from "./modals/auth/auth-reset-success.view";
import { AuthModal } from "./modals/auth/auth.modal";
import { FilterModal } from "./modals/filter/filter.modal";
import { SchedulingModalSelectDateView } from "./modals/scheduling/scheduling-select-date.view";
import { SchedulingModalSelectDetailsView } from "./modals/scheduling/scheduling-select-details.view";
import { SchedulingModal } from "./modals/scheduling/scheduling.modal";
import { HomePage } from "./pages/home/home.page";
import { SearchTutorPage } from "./pages/search-tutor/search-tutor.page";
import { SignUpAccessView } from "./pages/sign-up/sign-up-access.view";
import { SignUpEducationView } from "./pages/sign-up/sign-up-education.view";
import { SignUpProfileView } from "./pages/sign-up/sign-up-profile.view";
import { SignUpSuccessView } from "./pages/sign-up/sign-up-success.view";
import { SignUpTutorView } from "./pages/sign-up/sign-up-tutor.view";
import { SignUpPage } from "./pages/sign-up/sign-up.page";
import { MainLayout } from "./layout/main/main.layout";
import { TutoradoLayout } from "./layout/tutorado/tutorado.layout";
import { TutorLayout } from "./layout/tutor/tutor.layout";
import { DashboardPage } from "./pages/dashboard/dashboard.page";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <HomePage />,
        children: [
          {
            path: "auth",
            element: <AuthModal />,
            children: [
              {
                path: "login",
                element: <AuthModalLoginView />,
              },
              {
                path: "esqueci-minha-senha",
                element: <AuthModalForgotPasswordView />,
              },
              {
                path: "redefinir-senha",
                element: <AuthModalResetPasswordView />,
              },
              {
                path: "senha-redefinida",
                element: <AuthModalResetSuccess />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/cadastrar",
    element: <SignUpPage />,
    children: [
      {
        path: "",
        element: <SignUpAccessView />,
      },
      {
        path: "dados-educacionais",
        element: <SignUpEducationView />,
      },
      {
        path: "dados-de-perfil",
        element: <SignUpProfileView />,
      },
      {
        path: "dados-do-tutor",
        element: <SignUpTutorView />,
      },
      {
        path: "sucesso",
        element: <SignUpSuccessView />,
      },
    ],
  },
  {
    path: "/painel-de-controle",
    element: <TutorLayout />,
    children: [
      {
        path: "",
        element: <DashboardPage />,
      },
    ],
  },
  {
    path: "/buscar-tutor",
    element: <TutoradoLayout />,
    children: [
      {
        path: "",
        element: <SearchTutorPage />,
        children: [
          {
            path: "filtrar",
            element: <FilterModal />,
          },
          {
            path: "agendar",
            element: <SchedulingModal />,
            children: [
              {
                path: "",
                element: <SchedulingModalSelectDetailsView />,
              },
              {
                path: "selecionar-data-e-hora",
                element: <SchedulingModalSelectDateView />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
