import { styled } from "@mui/material";

const Left = styled('div')`
    margin-right: 8px;

    ${({ theme }) => theme.breakpoints.up('md')} {
        display: none;
    }
`

const Right = styled('div')`
    display: none;

    ${({ theme }) => theme.breakpoints.up('md')} {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px
    }

`

export const MainLayoutStyles = {
    Left,
    Right
}