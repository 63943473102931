import { ChangeEvent, FC, createRef } from "react";
import { Typography, Avatar, TextField } from "@mui/material";
import { Content, SelectPhoto, Instructions } from "./sign-up.styles";
import { useSignUp } from "./sign-up.hook";
import { BackAndContinue } from "../../components/back-and-continue/back-and-continue.component";
import { useNavigate } from "react-router-dom";
import { accessDataModel } from "../../validations/access-data";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { obterMensagensDeErro } from "../../validations/get-error-messages";

export const SignUpAccessView: FC = () => {
  const navegar = useNavigate();
  const { state, dispatch } = useSignUp();
  const { t } = useTranslation();

  const photoRef = createRef<HTMLInputElement>();

  const fotoEstaSelecionada = state.campos.fotoBase64.length > 1;

  const selecionarFoto = () => {
    photoRef.current?.click();
  };

  const fotoSelecionada = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          dispatch({ type: "DEFINIR_FOTO_BASE64", value: reader.result });
          dispatch({ type: "DEFINIR_FOTO_FILE", value: file });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const continuar = async () => {
    try {
      await accessDataModel.parseAsync({
        foto: state.campos.fotoBase64,
        nome: state.campos.nome,
        email: state.campos.email,
        senha: state.campos.senha,
      });

      navegar("/cadastrar/dados-educacionais");
    } catch (error) {
      if (error instanceof z.ZodError) {
        const mensagens = obterMensagensDeErro(error);
        console.log("Deu ruim?", mensagens);
        dispatch({ type: "DEFINIR_ERROS", erros: mensagens });
      }
    }
  };

  return (
    <Content
      sx={{
        maxWidth: {
          md: "400px",
        },
      }}
    >
      <Typography
        variant="h5"
        fontWeight="bold"
        letterSpacing={1.1}
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        {t("sign-up-access.bem-vindo-crie-seu-acesso")}
      </Typography>

      <SelectPhoto
        onClick={selecionarFoto}
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        <Avatar src={state.campos.fotoBase64} />
        <Instructions>
          <Typography variant="body1">
            {fotoEstaSelecionada ? "Atualizar foto" : "Selecionar foto"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            A foto precisa ter menos de 2mb
          </Typography>
          <input
            ref={photoRef}
            accept="image/jpeg"
            type="file"
            style={{ display: "none" }}
            onChange={fotoSelecionada}
          />
        </Instructions>
      </SelectPhoto>

      <TextField
        autoFocus
        type="text"
        label="Nome"
        value={state.campos.nome}
        onChange={(e) =>
          dispatch({ type: "DEFINIR_NOME", value: e.target.value })
        }
        error={state.erros.nome !== null}
        helperText={state.erros.nome}
        variant="standard"
        required
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="300"
      />

      <TextField
        type="email"
        label="E-mail"
        value={state.campos.email}
        onChange={(e) =>
          dispatch({ type: "DEFINIR_EMAIL", value: e.target.value })
        }
        error={state.erros.email !== null}
        helperText={
          state.erros.email ||
          "Informe um e-mail do domínio oficial de sua instituição de ensino"
        }
        variant="standard"
        required
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="500"
      />

      <TextField
        type="password"
        label="Senha"
        value={state.campos.senha}
        onChange={(e) =>
          dispatch({ type: "DEFINIR_SENHA", value: e.target.value })
        }
        error={state.erros.senha !== null}
        helperText={state.erros.senha}
        variant="standard"
        required
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="700"
      />

      <Typography variant="body2" mt="1em">
        Ao clicar em continuar você aceita os nossos{" "}
        <a href="/termos-de-uso">termos de uso</a> e{" "}
        <a href="/politica-de-privacidade">política de privacidade</a>.
      </Typography>

      <BackAndContinue voltar={() => navegar(-1)} continuar={continuar} />
    </Content>
  );
};
