import { styled } from "@mui/material";

const Background = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

const Image = styled('img')`
  width: 36px;
  margin-right: 8px;
  border-radius: 4px;
`;

export const LogoStyles = {
    Background,
    Image
}