import { Menu } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "../../components/navbar/navbar.component";
import { Profile } from "../../components/profile/profile.component";
import { TutoradoStyles } from "./tutorado.styles";

export const TutoradoLayout: FC = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Navbar
        esquerda={
          <TutoradoStyles.Left>
            <IconButton onClick={() => {}}>
              <Menu />
            </IconButton>
          </TutoradoStyles.Left>
        }
        direita={
          <TutoradoStyles.Right>
            <Profile />
          </TutoradoStyles.Right>
        }
        sx={{
          ".MuiToolbar-root": {
            justifyContent: "space-between",
          },
        }}
      />
      <Box height="16px" />
      <Outlet />
    </Box>
  );
};
