import { styled } from "@mui/material";

const Background = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px;

  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: ${({ theme }) => theme.shadows[3]};

  gap: 16px;
  user-select: none;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("md")} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Content = styled("div")``;

const IconWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #bfbfbf;
  border-radius: 8px;
`;


export const CustomRadioStyles = {
  Background,
  Content,
  IconWrapper
}
