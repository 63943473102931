import axios from "axios";
import { obterDados } from "../common/axios-methods";
import { BASE_URL } from "../common/base-url";
import { ObterCursosEntrada, ObterCursosSaida } from "./course.types";

const cursoAPI = axios.create({
    baseURL: `${BASE_URL}/curso`
})

export const obterCursos = (entrada: ObterCursosEntrada): Promise<ObterCursosSaida> => {
    return cursoAPI.get("/", { params: entrada }).then(obterDados)
}