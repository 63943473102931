import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { logar } from "../../api/auth/auth.api";
import { LogarEntrada, LogarSaida } from "../../api/auth/auth.types";
import { obterPerfil } from "../../api/student/student.api";
import { salvarTokens } from "../../utils/auth";
import { eTutor, eTutorado } from "../../utils/verify-profile";
import { Action, Bottom, Form } from "./auth.styles";

export const AuthModalLoginView: FC = () => {
  const navegar = useNavigate();

  const [email, definirEmail] = useState("");
  const [senha, definirSenha] = useState("");

  const [mensagemErroEmail] = useState<null | string>(null);
  const [mensagemErroSenha] = useState<null | string>(null);
  const [mensagemErro, definirMensagemErro] = useState<null | string>(null);

  const { t } = useTranslation();

  const {
    mutate,
    data: logarSaida,
    isPending,
  } = useMutation<LogarSaida, AxiosError, LogarEntrada>({
    mutationFn: (entrada) => logar(entrada),
    onSuccess({ access, refresh }) {
      salvarTokens(access, refresh);
    },
    onError() {
      definirMensagemErro(t("auth-login.e-mail-ou-senha-incorreta"));
    },
  });

  const { data: obterPerfilSaida } = useQuery({
    queryKey: ["perfil"],
    queryFn: () => obterPerfil(),
    enabled: logarSaida !== undefined,
  });

  const entrar = async () => {
    mutate({
      username: email,
      password: senha,
    });
  };

  if (eTutorado(obterPerfilSaida)) {
    return <Navigate to="/buscar-tutor" replace />;
  }

  if (eTutor(obterPerfilSaida)) {
    return <Navigate to="/painel-de-controle" replace />;
  }

  return (
    <Fragment>
      <Typography variant="h6" fontWeight="bold" mb="35px" textAlign="center">
        {t("comum.entrar")}
      </Typography>

      <Form>
        <TextField
          autoFocus
          type="email"
          label={t("comum.email")}
          variant="standard"
          required
          style={{ marginBottom: 25 }}
          value={email}
          onChange={(e) => {
            definirEmail(e.target.value);
            definirMensagemErro(null);
          }}
          disabled={isPending}
          error={mensagemErroEmail !== null}
          helperText={mensagemErroEmail}
        />
        <TextField
          type="password"
          label={t("comum.senha")}
          variant="standard"
          required
          value={senha}
          onChange={(e) => {
            definirSenha(e.target.value);
            definirMensagemErro(null);
          }}
          disabled={isPending}
          error={mensagemErroSenha !== null}
          helperText={mensagemErroSenha}
        />

        {mensagemErro !== null && (
          <Typography variant="body1" color={colors.red[500]} mt="1em">
            {mensagemErro}
          </Typography>
        )}
      </Form>

      <Button
        fullWidth
        variant="contained"
        onClick={entrar}
        disabled={isPending}
      >
        {isPending ? <CircularProgress size={20} /> : t("comum.entrar")}
      </Button>

      <Bottom>
        <Typography textAlign="center">
          {t("auth-login.voce-ainda-nao-possui-cadastro")}
        </Typography>
        <Action
          color="primary"
          variant="body1"
          paragraph
          onClick={() => navegar("/cadastrar")}
        >
          {t("comum.cadastre-se")}
        </Action>
        <Action onClick={() => navegar("/auth/esqueci-minha-senha")}>
          {t("auth-login.esqueci-minha-senha")}
        </Action>
      </Bottom>
    </Fragment>
  );
};
