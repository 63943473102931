import { Check } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { LineAndTextProps } from "../../pages/sign-up/sign-up.types";

export const LineAndText: FC<LineAndTextProps> = ({ text }) => {
  return (
    <Stack direction="row" spacing="8px">
      <Check fontSize="small" color="success" />
      <Typography variant="body2" color="text.secondary">
        {text}
      </Typography>
    </Stack>
  );
};
