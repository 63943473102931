import { LinearProgress } from "@mui/material";
import { FC, useReducer } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Navbar } from "../../components/navbar/navbar.component";
import { SignUpContexto } from "./sign-up.context";
import { SIGN_UP_INITIAL_STATE, signUpReducer } from "./sign-up.reducer";
import { Background, LeftContent, Main, RightContent } from "./sign-up.styles";

export const SignUpPage: FC = () => {
  const localizacao = useLocation();

  const [state, dispatch] = useReducer(signUpReducer, SIGN_UP_INITIAL_STATE);

  const porcentagens = new Map<string, number>([
    ["/cadastrar", 0],
    ["/cadastrar/dados-educacionais", 33],
    ["/cadastrar/dados-de-perfil", 66],
    ["/cadastrar/dados-do-tutor", 66],
    ["/cadastrar/sucesso", 100],
  ]);

  const paginaDeSucesso = localizacao.pathname === "/cadastrar/sucesso";
  const porcentagem = porcentagens.get(localizacao.pathname);

  return (
    <Background>
      <Navbar />
      <LinearProgress
        sx={{ height: "6px" }}
        variant="determinate"
        value={porcentagem}
      />
      <SignUpContexto.Provider value={{ state, dispatch }}>
        {paginaDeSucesso ? (
          <Outlet />
        ) : (
          <Main>
            <LeftContent>
              <Outlet />
            </LeftContent>
            <RightContent />
          </Main>
        )}
      </SignUpContexto.Provider>
    </Background>
  );
};
