import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { solicitarRedefinicaoDeSenha } from "../../api/auth/auth.api";
import { salvarTokens } from "../../utils/auth";

export const AuthModalForgotPasswordView: FC = () => {
  const navegar = useNavigate();
  const [carregando, definirCarregando] = useState(false);
  const [email, definirEmail] = useState("");
  const [mensagemErroEmail] = useState<null | string>(null);

  const { t } = useTranslation();

  const esqueciASenha = async () => {
    try {
      definirCarregando(true);

      const { access, refresh } = await solicitarRedefinicaoDeSenha({
        email,
      });

      salvarTokens(access, refresh);

      navegar("/auth/redefinir-senha");
    } catch (error) {
      console.log("Deu ruim no esqueci a senha", error);
    } finally {
      definirCarregando(false);
    }
  };

  return (
    <Fragment>
      <Typography variant="h6" fontWeight="bold" paragraph textAlign="center">
        {t("auth-forgot-password.redefinir-minha-senha")}
      </Typography>

      <Typography
        variant="body1"
        textAlign="center"
        paragraph
        color="text.secondary"
      >
        {t("auth-forgot-password.redefinir-minha-senha-instrucoes")}
      </Typography>

      <TextField
        autoFocus
        type="email"
        label={t("comum.email")}
        variant="standard"
        required
        style={{ marginBottom: 25 }}
        value={email}
        onChange={(e) => definirEmail(e.target.value)}
        disabled={carregando}
        error={mensagemErroEmail !== null}
        helperText={mensagemErroEmail}
        fullWidth
      />

      <Stack spacing={1}>
        <Button
          fullWidth
          variant="contained"
          onClick={esqueciASenha}
          disabled={carregando || email.length < 3}
        >
          {carregando ? <CircularProgress size={20} /> : t("comum.enviar")}
        </Button>
        <Button fullWidth variant="text" onClick={() => navegar("/auth/login")}>
          {t("auth-forgot-password.voltar-para-entrar")}
        </Button>
      </Stack>
    </Fragment>
  );
};
