import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useSearchTutor } from "../../pages/search-tutor/search-tutor.hooks";
import { useNavigate } from "react-router-dom";

export const SchedulingModalSelectDetailsView: FC = () => {
  const navegar = useNavigate();
  const { state, dispatch } = useSearchTutor();

  const disciplina = state.modalDeAgendamento.campos.disciplina;
  const interesse = state.modalDeAgendamento.campos.interesse;

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Typography variant="subtitle1" fontWeight="500">
        Selecione os detalhes da tutoria
      </Typography>

      <FormControl variant="standard" required>
        <InputLabel id="disciplina-label">Disciplina</InputLabel>
        <Select
          labelId="disciplina-label"
          id="disciplina"
          value={disciplina}
          onChange={(e) =>
            dispatch({
              type: "DEFINIR_CAMPO_DISCIPLINA",
              disciplina: e.target.value,
            })
          }
          label="Disciplina"
          disabled={interesse.length > 0}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Exemplo">Exemplo</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="standard" required>
        <InputLabel id="interesse-label">Interesse</InputLabel>
        <Select
          labelId="interesse-label"
          id="interesse"
          value={state.modalDeAgendamento.campos.interesse}
          onChange={(e) =>
            dispatch({
              type: "DEFINIR_CAMPO_INTERESSE",
              interesse: e.target.value,
            })
          }
          label="Interesse"
          disabled={disciplina.length > 0}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Exemplo">Exemplo</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="standard" required>
        <InputLabel id="objetivo-label">Objetivo</InputLabel>
        <Select
          labelId="objetivo-label"
          id="interesse"
          value={state.modalDeAgendamento.campos.objetivo}
          onChange={(e) =>
            dispatch({
              type: "DEFINIR_CAMPO_OBJETIVO",
              objetivo: e.target.value,
            })
          }
          label="Objetivo"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Exemplo">Exemplo</MenuItem>
        </Select>
      </FormControl>

      <Button
        onClick={() => navegar("/buscar-tutor/agendar/selecionar-data-e-hora")}
        size="large"
        variant="contained"
        color="primary"
      >
        Continuar
      </Button>
    </Box>
  );
};
