import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FC } from "react";
import { RouterProvider } from "react-router-dom";
import { queryClient } from "./clients/query-client";
import { router } from "./router";
import theme from "./theme";
import { TranslationProvider } from "./provider/translation-provider";

export const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <TranslationProvider>
          <RouterProvider router={router} />
        </TranslationProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ThemeProvider>
  );
};
