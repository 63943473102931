import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { redefinirSenhaDoEstudante } from "../../api/student/student.api";

export const AuthModalResetPasswordView: FC = () => {
  const navegar = useNavigate();
  const [senha, definirSenha] = useState("");
  const [carregando, definirCarregando] = useState(false);
  const [mensagemErroSenha] = useState<null | string>(null);

  const { t } = useTranslation();

  const alterarSenha = async () => {
    try {
      definirCarregando(true);

      await redefinirSenhaDoEstudante({
        senha,
      });

      navegar("/auth/senha-redefinida", { state: { sucesso: true } });
    } catch (error) {
      console.log("Deu ruim na alteração");
    } finally {
      definirCarregando(false);
    }
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h6" fontWeight="bold" paragraph textAlign="center">
        {t("auth-reset-password.defina-uma-nova-senha")}
      </Typography>

      <TextField
        autoFocus
        type="password"
        label={t("comum.senha")}
        variant="standard"
        required
        value={senha}
        onChange={(e) => definirSenha(e.target.value)}
        disabled={carregando}
        error={mensagemErroSenha !== null}
        helperText={mensagemErroSenha}
        fullWidth
      />

      <Button
        fullWidth
        variant="contained"
        onClick={alterarSenha}
        disabled={carregando || senha.length < 3}
      >
        {carregando ? <CircularProgress size={20} /> : t("comum.confirmar")}
      </Button>
    </Stack>
  );
};
