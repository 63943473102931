import { createContext } from "react";

export type Language = "pt-BR" | "en-US";

export type TranslationContextValue = {
  linguagem: Language;
  linguagens: Language[];
  alterarLinguagem: (linguagem: Language) => void;
  linguagemEstaAtiva: (linguagem: Language) => boolean;
} | null;

export const TranslationContext = createContext<TranslationContextValue>(null);
