import axios from "axios";
import { BASE_URL } from "../common/base-url";
import { obterDados } from "../common/axios-methods";
import { CriarTutorEntrada, CriarTutorSaida } from "./tutor.types";
import { adicionarTokenInterceptor } from "../common/add-token-interceptor";

const tutorAPI = axios.create({
    baseURL: `${BASE_URL}/tutor`
})

tutorAPI.interceptors.request.use(adicionarTokenInterceptor)

export const criarTutor = (entrada: CriarTutorEntrada): Promise<CriarTutorSaida> => {
    return tutorAPI.post('/', entrada).then(obterDados)
}