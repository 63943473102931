import SchoolIcon from "@mui/icons-material/School";
import {
  Avatar,
  Box,
  Container,
  Fade,
  Grid,
  Modal,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSearchTutor } from "../../pages/search-tutor/search-tutor.hooks";
import { ModalCloseButton } from "../../components/modal-close-button/modal-close-button.component";
import { ModalBody } from "./scheduling.styles";

const TutorProfile = () => {
  const { state } = useSearchTutor();

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
      mb="1em"
    >
      <Avatar src={state.tutorSelecionado.fotoPerfil} />
      <Typography variant="body1" fontWeight="bold">
        {state.tutorSelecionado.nome}
      </Typography>
    </Stack>
  );
};

const Details = () => {
  const { state } = useSearchTutor();

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Typography variant="subtitle1" fontWeight="500">
        Sessão de mentoria
      </Typography>

      <Box>
        <Typography component="legend">
          Nota média: {state.tutorSelecionado.notaMedia}
        </Typography>
        <Rating
          name="simple-controlled"
          value={state.tutorSelecionado.notaMedia}
          readOnly
          precision={0.1}
        />
      </Box>

      <Stack spacing={1} direction="row">
        <SchoolIcon />
        <Typography>
          {state.tutorSelecionado.quantidadeTutorias}{" "}
          {state.tutorSelecionado.quantidadeTutorias === 1
            ? "tutoria ministrada."
            : "tutorias ministradas."}
        </Typography>
      </Stack>
    </Box>
  );
};

/**
 * Começar pelo interesse/disciplina e objetivo
 * Calendario
 * Horário de inicio e termino de fim
 */

export const SchedulingModal: FC = () => {
  const navegar = useNavigate();

  return (
    <Modal open>
      <Fade in>
        <Container>
          <ModalBody>
            <ModalCloseButton onClick={() => navegar("/buscar-tutor")} />

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  paddingRight: {
                    md: "1em",
                  },
                  borderRight: {
                    md: "1px solid #ccc",
                  },
                }}
                padding="1em"
              >
                <TutorProfile />
                <Box component="hr" border="1px solid #ccc" mb="1em" />
                <Details />
              </Grid>
              <Grid item xs={12} md={6}>
                <Outlet />
              </Grid>
            </Grid>
          </ModalBody>
        </Container>
      </Fade>
    </Modal>
  );
};
