import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FC } from "react";
import { useSearchTutor } from "../../pages/search-tutor/search-tutor.hooks";
import moment from "moment-timezone";

export const SchedulingModalSelectDateView: FC = () => {
  const { state, dispatch } = useSearchTutor();

  const dataInicio = state.modalDeAgendamento.campos.dataInicio;

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Typography variant="subtitle1" fontWeight="500">
        Selecione data e hora
      </Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          label="Data e hora de início"
          format="DD/MM/YYYY HH:mm"
          onChange={(data) => {
            dispatch({
              type: "DEFINIR_CAMPO_DATA_INICIO",
              dataInicio: data!,
            });
          }}
        />
      </LocalizationProvider>

      <FormControl variant="standard" required>
        <InputLabel id="duracao-label">Duração</InputLabel>
        <Select
          labelId="duracao-label"
          id="duracao"
          onChange={(e) =>
            dispatch({
              type: "DEFINIR_CAMPO_DATA_FIM",
              dataFim: moment(e.target.value as string),
            })
          }
          label="Duração"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {/* Precisa alterar para o formato que o Back vai receber */}
          <MenuItem value={moment(dataInicio).add(30, "minutes").toISOString()}>
            30 minutos
          </MenuItem>
          <MenuItem value={moment(dataInicio).add(1, "hour").toISOString()}>
            1 hora
          </MenuItem>
        </Select>
      </FormControl>

      <Button size="large" variant="contained" color="primary">
        Continuar
      </Button>
    </Box>
  );
};
