import {
  Button,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
  styled,
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchTutor } from "../../pages/search-tutor/search-tutor.hooks";
import { ModalCloseButton } from "../../components/modal-close-button/modal-close-button.component";

const ModalBody = styled("div")`
  top: 50%;
  left: 50%;
  position: absolute;

  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 50px;

  background-color: #f8f8ff;

  border-radius: 20px;
  width: 100%;
  max-width: 340px;
`;

export const FilterModal: FC = () => {
  const navegar = useNavigate();
  const { state, dispatch } = useSearchTutor();

  const interesse = state.filtros.interesse;
  const disciplina = state.filtros.disciplina;

  const filtrar = () => {};

  return (
    <Modal open>
      <Fade in>
        <ModalBody>
          <ModalCloseButton onClick={() => navegar(-1)} />

          <Typography variant="h6" textAlign="center">
            Filtros
          </Typography>

          <FormControl variant="standard">
            <InputLabel id="disciplina-label">Disciplina</InputLabel>
            <Select
              autoFocus
              labelId="disciplina-label"
              id="disciplina"
              value={disciplina}
              onChange={(e) =>
                dispatch({
                  type: "DEFINIR_FILTRO_DISCIPLINA",
                  disciplina: e.target.value,
                })
              }
              disabled={interesse.length > 0}
              label="Disciplina"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Exemplo">Exemplo</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="standard">
            <InputLabel id="interesse-label">Interesse</InputLabel>
            <Select
              labelId="interesse-label"
              id="interesse"
              value={interesse}
              onChange={(e) =>
                dispatch({
                  type: "DEFINIR_FILTRO_INTERESSE",
                  interesse: e.target.value,
                })
              }
              label="Interesse"
              disabled={disciplina.length > 0}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Exemplo">Exemplo</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            fullWidth
            size="large"
            disabled={disciplina.length === 0 && interesse.length === 0}
            onClick={filtrar}
          >
            Exibir resultados
          </Button>
        </ModalBody>
      </Fade>
    </Modal>
  );
};
