import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Language,
  TranslationContext,
  TranslationContextValue,
} from "../context/translation-context";

export const TranslationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();

  const [linguagem, setLinguagem] = useState<Language>("pt-BR");

  const alterarLinguagem = useCallback(
    (linguagem: Language) => {
      i18n.changeLanguage(linguagem);
      setLinguagem(linguagem);
    },
    [i18n]
  );

  const linguagemEstaAtiva = useCallback(
    (ling: Language) => {
      return linguagem === ling;
    },
    [linguagem]
  );

  const value = useMemo<TranslationContextValue>(
    () => ({
      linguagem,
      linguagens: ["pt-BR", "en-US"],
      alterarLinguagem,
      linguagemEstaAtiva,
    }),
    [alterarLinguagem, linguagem, linguagemEstaAtiva]
  );

  useEffect(() => {
    const linguagem = (localStorage.getItem("linguagem") ??
      "pt-BR") as Language;

    i18n.changeLanguage(linguagem);
    setLinguagem(linguagem);
  }, [i18n]);

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};
