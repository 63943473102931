import { Menu, MenuItem } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "../../hooks/useTranslation";
import { bindMenu } from "material-ui-popup-state";
import { Language } from "../../context/translation-context";
import { PopupState } from "material-ui-popup-state/hooks";

export type LanguageMenuProps = {
  popupState: PopupState;
};

export const LanguageMenu: FC<LanguageMenuProps> = ({ popupState }) => {
  const traducao = useTranslation();

  const lidarComAlterarLinguagem = (linguagem: Language) => {
    return () => {
      localStorage.setItem("linguagem", linguagem);
      traducao.alterarLinguagem(linguagem);
      popupState.close();
    };
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...bindMenu(popupState)}
    >
      <MenuItem
        disabled={traducao.linguagemEstaAtiva("en-US")}
        onClick={lidarComAlterarLinguagem("en-US")}
      >
        Inglês
      </MenuItem>
      <MenuItem
        disabled={traducao.linguagemEstaAtiva("pt-BR")}
        onClick={lidarComAlterarLinguagem("pt-BR")}
      >
        Português
      </MenuItem>
    </Menu>
  );
};
