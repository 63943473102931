import { NoteAdd } from "@mui/icons-material";
import { FC } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { DropzoneStyles } from "./dropzone.styles";

export type DropzoneProps = {
  dropzoneText?: string;
  dropzoneHoverText?: string;
  invalidText?: string;
  accept?: DropzoneOptions["accept"];
  maxFiles?: DropzoneOptions["maxFiles"];
  onDrop?: DropzoneOptions["onDrop"];
};

export const Dropzone: FC<DropzoneProps> = ({
  dropzoneText,
  dropzoneHoverText,
  invalidText,
  onDrop,
  accept,
  maxFiles,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    accept,
    maxFiles,
  });

  const arquivoCarregado = acceptedFiles.length === 1;
  const arquivo = acceptedFiles[0] as File | undefined;

  return (
    <DropzoneStyles.Area {...getRootProps()}>
      <input {...getInputProps()} />

      <NoteAdd fontSize="large" />

      {isDragReject && (
        <DropzoneStyles.Text variant="body2" color="text.secondary">
          {invalidText}
        </DropzoneStyles.Text>
      )}

      {isDragActive && isDragAccept && (
        <DropzoneStyles.Text variant="body2" color="text.secondary">
          {dropzoneHoverText}
        </DropzoneStyles.Text>
      )}

      {!isDragActive && !arquivoCarregado && (
        <DropzoneStyles.Text variant="body2">
          {dropzoneText}
        </DropzoneStyles.Text>
      )}

      {arquivo && (
        <DropzoneStyles.Text variant="body2">
          Arquivo selecionado: <br />
          {arquivo.name}
        </DropzoneStyles.Text>
      )}
    </DropzoneStyles.Area>
  );
};
