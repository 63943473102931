export type Tutor = {
  id: string;
  nome: string;
  fotoPerfil: string;
  notaMedia: number;
  quantidadeTutorias: number;
};

export enum Perfil {
  TUTORADO = "TUTORADO",
  TUTOR = "TUTOR",
}
