import { Fade, Modal } from "@mui/material";
import { FC } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ModalCloseButton } from "../../components/modal-close-button/modal-close-button.component";
import { ModalBody } from "./auth.styles";

export const AuthModal: FC = () => {
  const navegar = useNavigate();

  return (
    <Modal open>
      <Fade in>
        <ModalBody>
          <ModalCloseButton onClick={() => navegar("/")} />
          <Outlet />
        </ModalBody>
      </Fade>
    </Modal>
  );
};
