import { Person, School } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { FC } from "react";
import { CustomRadio } from "../../components/custom-radio/custom-radio.component";
import { LineAndText } from "../../components/line-and-text/line-and-text.component";
import { Perfil } from "../../types";
import { useSignUp } from "./sign-up.hook";
import { Content } from "./sign-up.styles";
import { BackAndContinue } from "../../components/back-and-continue/back-and-continue.component";
import { useNavigate } from "react-router-dom";
import { criarTutorado } from "../../api/tutee/tutee.api";
import { useMutation } from "@tanstack/react-query";
import {
  CriarTutoradoEntrada,
  CriarTutoradoSaida,
} from "../../api/tutee/tutee.types";
import { AxiosError } from "axios";

export const SignUpProfileView: FC = () => {
  const navegar = useNavigate();
  const { state, dispatch } = useSignUp();

  const { mutateAsync, isPending } = useMutation<
    CriarTutoradoSaida,
    AxiosError,
    CriarTutoradoEntrada
  >({
    mutationKey: ["criar-tutorado"],
    mutationFn: (entrada) => criarTutorado(entrada),
  });

  const continuar = async () => {
    if (state.campos.perfil === Perfil.TUTORADO) {
      try {
        await mutateAsync({
          id_curso: state.campos.curso!,
          id_instituicao_ensino: state.campos.instituicaoDeEnsino!,
          prontuario: state.campos.prontuario,
        });

        navegar("/cadastrar/sucesso", {
          state: { perfil: state.campos.perfil },
          replace: true,
        });
      } catch (error) {
        //
      }
    }

    if (state.campos.perfil === Perfil.TUTOR) {
      navegar("/cadastrar/dados-do-tutor");
    }
  };

  return (
    <Content>
      <Typography
        variant="h5"
        fontWeight="bold"
        letterSpacing={1.1}
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        Escolha seu tipo de perfil
      </Typography>
      <CustomRadio
        selecionado={state.campos.perfil === Perfil.TUTORADO}
        onClick={() =>
          dispatch({ type: "DEFINIR_PERFIL", perfil: Perfil.TUTORADO })
        }
        titulo="Tutorado"
        icon={<Person />}
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        {[
          "Encontre tutores na sua universidade",
          "Agende sessões de tutoria",
          "Desenvolva seu conhecimento em disciplinas e interesses",
        ].map((text, index) => (
          <LineAndText key={index} text={text} />
        ))}
      </CustomRadio>
      <CustomRadio
        titulo="Tutor"
        icon={<School />}
        selecionado={state.campos.perfil === Perfil.TUTOR}
        onClick={() =>
          dispatch({ type: "DEFINIR_PERFIL", perfil: Perfil.TUTOR })
        }
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="600"
      >
        {[
          "Ministre tutorias na sua universidade",
          "Aceite tutorias de acordo com a sua disponibilidade",
          "Ajude seus colegas e desenvolva suas habilidades",
        ].map((text, index) => (
          <LineAndText key={index} text={text} />
        ))}
      </CustomRadio>

      <BackAndContinue
        carregando={isPending}
        voltar={() => navegar(-1)}
        continuar={continuar}
      />
    </Content>
  );
};
