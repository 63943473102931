import { z } from 'zod'
import { nome } from './name'
import { email } from './email'
import { senha } from './password'
import { foto } from './picture'

export const accessDataModel = z.object({
    nome,
    email,
    senha,
    foto
})