import axios from "axios"
import { BASE_URL } from "../common/base-url"
import { obterDados } from "../common/axios-methods"
import { ObterInteressesEntrada, ObterInteressesSaida } from "./interest.types"

const interesseAPI = axios.create({
    baseURL: `${BASE_URL}/interesse`
})

export const obterInteresses = (entrada?: ObterInteressesEntrada): Promise<ObterInteressesSaida> => {
    return interesseAPI.get('/', {
        params: entrada
    }).then(obterDados)
}