import { Typography, styled } from "@mui/material";

const Area = styled("div")`
  width: 100%;
  height: 200px;

  background-color: #bfbfbf;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  border-radius: 4px;

  svg {
    color: #595959;
  }
`;

const Text = styled(Typography)`
  text-align: center;
  max-width: 300px;
  color: #595959;
`;

export const DropzoneStyles = {
    Area,
    Text
}