import { Typography, styled } from "@mui/material";

export const ModalBody = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 50px;
  background-color: #f8f8ff;

  border-radius: 20px;
  width: 100%;
  max-width: 340px;
`;

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Bottom = styled('div')`
  margin-top: 40px;
`;

export const Action = styled(Typography)`
  text-align: center;
  user-select: none;
  cursor: pointer;
`;