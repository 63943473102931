import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { criarUsuario, validarEmail } from "../../api/auth/auth.api";
import { obterCursos } from "../../api/course/course.api";
import { obterInstituicoes } from "../../api/institution/institution.api";
import { BackAndContinue } from "../../components/back-and-continue/back-and-continue.component";
import { salvarTokens } from "../../utils/auth";
import { educationDataModel } from "../../validations/education-data";
import { obterMensagensDeErro } from "../../validations/get-error-messages";
import { useSignUp } from "./sign-up.hook";
import { Content } from "./sign-up.styles";
import {
  CriarUsuarioEntrada,
  CriarUsuarioSaida,
} from "../../api/auth/auth.types";
import { AxiosError } from "axios";
import { enviarFotoDePerfil } from "../../api/student/student.api";
import {
  EnviarFotoDePerfilEntrada,
  EnviarFotoDePerfilSaida,
} from "../../api/student/student.types";

export const SignUpEducationView: FC = () => {
  const navegar = useNavigate();
  const { state, dispatch } = useSignUp();

  const { data: instituicoes, isLoading: carregandoInstituicoes } = useQuery({
    queryKey: ["instituicoes"],
    queryFn: () => obterInstituicoes(),
  });

  const { data: cursos, isLoading: carregandoCursos } = useQuery({
    queryKey: ["cursos", state.campos.instituicaoDeEnsino],
    queryFn: () =>
      obterCursos({ id_instituicao_ensino: state.campos.instituicaoDeEnsino! }),
    enabled: state.campos.instituicaoDeEnsino !== null,
  });

  const {
    mutateAsync: criarUsuarioMutateAsync,
    isPending: criarUsuarioIsPending,
  } = useMutation<CriarUsuarioSaida, AxiosError, CriarUsuarioEntrada>({
    mutationKey: ["criar-usuario"],
    mutationFn: (entrada) => criarUsuario(entrada),
  });

  const {
    mutateAsync: enviarFotoDePerfilMutateAsync,
    isPending: enviarFotoDePerfilIsPending,
  } = useMutation<
    EnviarFotoDePerfilSaida,
    AxiosError,
    EnviarFotoDePerfilEntrada
  >({
    mutationKey: ["enviar-foto-de-perfil"],
    mutationFn: (entrada) => enviarFotoDePerfil(entrada),
  });

  const continuar = async () => {
    try {
      await educationDataModel.parseAsync({
        instituicaoDeEnsino: state.campos.instituicaoDeEnsino!,
        curso: state.campos.curso,
        prontuario: state.campos.prontuario,
      });

      await validarEmail({
        email: state.campos.email,
        id_instituicao: state.campos.instituicaoDeEnsino!,
      });

      const nomeQuebrado = state.campos.nome.split(" ");
      const nome = nomeQuebrado.shift();
      const sobrenome = nomeQuebrado.join(" ");

      const { access, refresh } = await criarUsuarioMutateAsync({
        nome: nome!,
        email: state.campos.email,
        senha: state.campos.senha,
        sobrenome: sobrenome,
      });

      salvarTokens(access, refresh);

      await enviarFotoDePerfilMutateAsync({
        file: state.campos.fotoFile!,
      });

      navegar("/cadastrar/dados-de-perfil", { replace: true });
    } catch (error) {
      if (error instanceof z.ZodError) {
        const mensagens = obterMensagensDeErro(error);
        console.log("Mensagens", mensagens);
        dispatch({ type: "DEFINIR_ERROS", erros: mensagens });
        return;
      }

      const instituicao = instituicoes?.results.find(
        (instituicao) => instituicao.id === state.campos.instituicaoDeEnsino
      );

      dispatch({
        type: "DEFINIR_ERROS",
        erros: {
          email: `Insira um domínio válido para a instituição ${instituicao?.nome}`,
        },
      });

      navegar("/cadastrar");
    }
  };

  return (
    <Content>
      <Typography
        variant="h5"
        fontWeight="bold"
        letterSpacing={1.1}
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        Informe seus dados educacionais
      </Typography>

      <FormControl
        variant="standard"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="300"
        required
      >
        <InputLabel id="instituicao-de-ensino-label">
          Instituição de ensino
        </InputLabel>
        <Select
          labelId="instituicao-de-ensino-label"
          id="instituicao-de-ensino"
          value={state.campos.instituicaoDeEnsino}
          onChange={(e) =>
            dispatch({
              type: "DEFINIR_INSTITUICAO",
              value: Number(e.target.value),
            })
          }
          label="Instituição de ensino"
          disabled={carregandoInstituicoes}
        >
          {instituicoes?.results.map((instituicao) => (
            <MenuItem key={instituicao.id} value={instituicao.id}>
              {instituicao.nome}
            </MenuItem>
          ))}
        </Select>
        {carregandoInstituicoes && <LinearProgress variant="indeterminate" />}
        {state.erros.instituicaoDeEnsino !== null && (
          <FormHelperText error>
            {state.erros.instituicaoDeEnsino}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl
        variant="standard"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="300"
        required
      >
        <InputLabel id="curso-label">Curso</InputLabel>
        <Select
          labelId="curso-label"
          id="curso"
          value={state.campos.curso}
          onChange={(e) =>
            dispatch({ type: "DEFINIR_CURSO", value: Number(e.target.value) })
          }
          label="Curso"
          disabled={
            state.campos.instituicaoDeEnsino === null || carregandoCursos
          }
        >
          {cursos?.results.map((curso) => (
            <MenuItem key={curso.id} value={curso.id}>
              {curso.nome}
            </MenuItem>
          ))}
        </Select>
        {carregandoCursos && <LinearProgress variant="indeterminate" />}
        {state.erros.curso !== null && (
          <FormHelperText error>{state.erros.curso}</FormHelperText>
        )}
      </FormControl>

      <TextField
        type="text"
        label="Prontuário"
        value={state.campos.prontuario}
        onChange={(e) =>
          dispatch({ type: "DEFINIR_PRONTUARIO", value: e.target.value })
        }
        error={state.erros.prontuario !== null}
        helperText={state.erros.prontuario}
        variant="standard"
        required
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="600"
      />

      <BackAndContinue
        carregando={criarUsuarioIsPending || enviarFotoDePerfilIsPending}
        voltar={() => navegar(-1)}
        continuar={continuar}
      />
    </Content>
  );
};
