import moment from "moment-timezone";
import type { SearchTutorAcoes, SearchTutorState } from "./search-tutor.types";

export const SEARCH_TUTOR_INITIAL_STATE: SearchTutorState = {
    modalDeAgendamento: {
        aberto: false,
        campos: {
            disciplina: "",
            interesse: "",
            objetivo: "",
            dataInicio: moment().tz("America/Sao_Paulo"),
            dataFim: moment().tz("America/Sao_Paulo")
        }
    },
    filtros: {
        disciplina: "",
        interesse: "",
    },
    tutorSelecionado: {
        id: "",
        fotoPerfil: "",
        nome: "",
        notaMedia: 0,
        quantidadeTutorias: 0
    }
};

export const searchTutorReducer = (
    state: SearchTutorState = SEARCH_TUTOR_INITIAL_STATE,
    action: SearchTutorAcoes
): SearchTutorState => {
    switch (action.type) {
        case "ABRIR_MODAL_DE_AGENDAMENTO":
            return {
                ...state, modalDeAgendamento: {
                    ...state.modalDeAgendamento,
                    aberto: true
                }
            };
        case "FECHAR_MODAL_DE_AGENDAMENTO":
            return {
                ...state, modalDeAgendamento: {
                    ...state.modalDeAgendamento,
                    aberto: false
                }
            };
        case "DEFINIR_FILTRO_DISCIPLINA":
            return {
                ...state,
                filtros: {
                    ...state.filtros,
                    disciplina: action.disciplina,
                },
            };
        case "DEFINIR_FILTRO_INTERESSE":
            return {
                ...state,
                filtros: {
                    ...state.filtros,
                    interesse: action.interesse,
                },
            };
        case "SELECIONAR_TUTOR":
            return {
                ...state,
                modalDeAgendamento: {
                    ...state.modalDeAgendamento,
                    aberto: true,
                },
                tutorSelecionado: action.tutor,

            }
        case "DEFINIR_CAMPO_DISCIPLINA":
            return {
                ...state, modalDeAgendamento: {
                    ...state.modalDeAgendamento,
                    campos: {
                        ...state.modalDeAgendamento.campos,
                        disciplina: action.disciplina
                    }
                }
            }
        case "DEFINIR_CAMPO_INTERESSE":
            return {
                ...state, modalDeAgendamento: {
                    ...state.modalDeAgendamento,
                    campos: {
                        ...state.modalDeAgendamento.campos,
                        interesse: action.interesse
                    }
                }
            }
        case "DEFINIR_CAMPO_DATA_INICIO":
            return {
                ...state, modalDeAgendamento: {
                    ...state.modalDeAgendamento,
                    campos: {
                        ...state.modalDeAgendamento.campos,
                        dataInicio: action.dataInicio
                    }
                }
            }
        case "DEFINIR_CAMPO_DATA_FIM":
            return {
                ...state, modalDeAgendamento: {
                    ...state.modalDeAgendamento,
                    campos: {
                        ...state.modalDeAgendamento.campos,
                        dataFim: action.dataFim
                    }
                }
            }
        case "DEFINIR_CAMPO_OBJETIVO":
            return {
                ...state, modalDeAgendamento: {
                    ...state.modalDeAgendamento,
                    campos: {
                        ...state.modalDeAgendamento.campos,
                        objetivo: action.objetivo
                    }
                }
            }
        default:
            return state;
    }
};
