import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import SchoolIcon from "@mui/icons-material/School";
import { Tutor } from "../../types";
import { useTranslation } from "react-i18next";

export type CardTutorProps = {
  tutor: Tutor;
  agendarTutoria?: (tutor: Tutor) => void;
};

export const CardTutor: FC<CardTutorProps> = ({ tutor, agendarTutoria }) => {
  const { t } = useTranslation();
  const singular = tutor.quantidadeTutorias === 1;

  return (
    <Card>
      <CardMedia
        component="img"
        alt=""
        height="140"
        image={tutor.fotoPerfil}
        style={{ objectPosition: "0 -56px" }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {tutor.nome}
        </Typography>
        <Typography component="legend">
          {t("card-tutor.nota-media")}: {tutor.notaMedia}
        </Typography>
        <Rating
          name="simple-controlled"
          value={tutor.notaMedia}
          readOnly
          precision={0.1}
        />
        <Stack spacing={1} direction="row">
          <SchoolIcon />
          <Typography>
            {tutor.quantidadeTutorias}{" "}
            {singular
              ? t("card-tutor.tutoria-ministrada")
              : t("card-tutor.tutorias-ministradas")}
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() => {
            if (agendarTutoria) {
              agendarTutoria(tutor);
            }
          }}
        >
          {t("card-tutor.agendar-tutoria")}
        </Button>
      </CardActions>
    </Card>
  );
};
