import axios from "axios";
import { obterDados } from "../common/axios-methods";
import { BASE_URL } from "../common/base-url";
import { CriarUsuarioEntrada, CriarUsuarioSaida, LogarEntrada, LogarSaida, RenovarTokenDeAcessoEntrada, RenovarTokenDeAcessoSaida, SolicitarRedefinicaoDeSenhaEntrada, SolicitarRedefinicaoDeSenhaSaida, ValidarEmailEntrada, VerificarTokenEntrada } from "./auth.types";

const authAPI = axios.create({
    baseURL: `${BASE_URL}/auth`
})

export const logar = async (entrada: LogarEntrada): Promise<LogarSaida> => {
    return authAPI.post("/token/", entrada).then(obterDados)
}

export const renovarTokenDeAcesso = async (entrada: RenovarTokenDeAcessoEntrada): Promise<RenovarTokenDeAcessoSaida> => {
    return authAPI.post("/token/refresh/", entrada).then(obterDados)
}

export const solicitarRedefinicaoDeSenha = async (entrada: SolicitarRedefinicaoDeSenhaEntrada): Promise<SolicitarRedefinicaoDeSenhaSaida> => {
    return authAPI.post("/token/reset/", entrada).then(obterDados)
}

export const verificarToken = async (entrada: VerificarTokenEntrada): Promise<void> => {
    return authAPI.post('/token/verify/', entrada)
}

export const validarEmail = async (entrada: ValidarEmailEntrada): Promise<void> => {
    return authAPI.post('/usuario/valida-email', entrada)
}


export const criarUsuario = async (entrada: CriarUsuarioEntrada): Promise<CriarUsuarioSaida> => {
    return authAPI.post('/usuario', entrada).then(obterDados)
}

