import { Perfil } from "../../types";
import { Etapa, SignUpAcoes, SignUpEstado } from "./sign-up.types";

export const SIGN_UP_INITIAL_STATE: SignUpEstado = {
    etapa: Etapa.DADOS_DE_ACESSO,
    campos: {
        fotoBase64: "",
        fotoFile: null,
        historicoBase64: "",
        historicoFile: null,
        nome: "",
        email: "",
        senha: "",
        instituicaoDeEnsino: null,
        curso: null,
        prontuario: "",
        perfil: Perfil.TUTORADO,
        disciplinas: [],
        interesses: [],
        justificativa: "",
    },
    erros: {
        fotoBase64: null,
        historicoBase64: null,
        nome: null,
        email: null,
        senha: null,
        instituicaoDeEnsino: null,
        curso: null,
        prontuario: null,
    },
};


export const signUpReducer = (
    state: SignUpEstado = SIGN_UP_INITIAL_STATE,
    action: SignUpAcoes
): SignUpEstado => {
    switch (action.type) {
        case "DEFINIR_NOME":
            return {
                ...state,
                erros: {
                    ...state.erros,
                    nome: null,
                },
                campos: {
                    ...state.campos,
                    nome: action.value,
                },
            };
        case "DEFINIR_FOTO_BASE64":
            return {
                ...state,
                erros: {
                    ...state.erros,
                    fotoBase64: null,
                },
                campos: {
                    ...state.campos,
                    fotoBase64: action.value,
                },
            };
        case "DEFINIR_FOTO_FILE":
            return {
                ...state,
                campos: {
                    ...state.campos,
                    fotoFile: action.value,
                },
            };
        case "DEFINIR_HISTORICO_BASE64":
            return {
                ...state,
                erros: {
                    ...state.erros,
                    historicoBase64: null,
                },
                campos: {
                    ...state.campos,
                    historicoBase64: action.value,
                },
            };
        case "DEFINIR_HISTORICO_FILE":
            return {
                ...state,
                campos: {
                    ...state.campos,
                    historicoFile: action.value,
                },
            };
        case "DEFINIR_EMAIL":
            return {
                ...state,
                erros: {
                    ...state.erros,
                    email: null,
                },
                campos: {
                    ...state.campos,
                    email: action.value,
                },
            };
        case "DEFINIR_SENHA":
            return {
                ...state,
                erros: {
                    ...state.erros,
                    senha: null,
                },
                campos: {
                    ...state.campos,
                    senha: action.value,
                },
            };
        case "DEFINIR_INSTITUICAO":
            return {
                ...state,
                erros: {
                    ...state.erros,
                    instituicaoDeEnsino: null,
                },
                campos: { ...state.campos, instituicaoDeEnsino: action.value },
            };
        case "DEFINIR_CURSO":
            return {
                ...state,
                erros: {
                    ...state.erros,
                    curso: null,
                },
                campos: { ...state.campos, curso: action.value }
            };
        case "DEFINIR_PRONTUARIO":
            return {
                ...state,
                erros: {
                    ...state.erros,
                    prontuario: null,
                },
                campos: { ...state.campos, prontuario: action.value },
            };
        case "DEFINIR_ETAPA":
            return {
                ...state,
                etapa: action.etapa,
            };
        case "DEFINIR_PERFIL":
            return {
                ...state,
                campos: {
                    ...state.campos,
                    perfil: action.perfil,
                },
            };
        case "DEFINIR_DISCIPLINAS":
            return {
                ...state,
                campos: {
                    ...state.campos,
                    disciplinas: [...action.disciplinas],
                },
            };
        case "DEFINIR_INTERESSES":
            return {
                ...state,
                campos: {
                    ...state.campos,
                    interesses: [...action.interesses],
                },
            };
        case "DEFINIR_JUSTIFICATIVA":
            return {
                ...state,
                campos: {
                    ...state.campos,
                    justificativa: action.justificativa,
                },
            };
        case "DEFINIR_ERROS":
            return {
                ...state,
                erros: {
                    ...state.erros,
                    ...action.erros,
                },
            };
        default:
            return state;
    }
};