import { SearchTutorContext } from "./search-tutor.context";
import { Dispatch, FC, PropsWithChildren } from "react";
import { SearchTutorAcoes, SearchTutorState } from "./search-tutor.types";

type SearchTutorProviderProps = PropsWithChildren<{
  state: SearchTutorState;
  dispatch: Dispatch<SearchTutorAcoes>;
}>;

export const SearchTutorProvider: FC<SearchTutorProviderProps> = ({
  state,
  dispatch,
  children,
}) => {
  return (
    <SearchTutorContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </SearchTutorContext.Provider>
  );
};
