import { Autocomplete, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { obterInteresses } from "../../api/interest/interest.api";
import { obterDisciplinas } from "../../api/subject/subject.api";
import { BackAndContinue } from "../../components/back-and-continue/back-and-continue.component";
import { Dropzone } from "../../components/dropzone/dropzone.component";
import { useSignUp } from "./sign-up.hook";
import { Content } from "./sign-up.styles";
import { criarTutor } from "../../api/tutor/tutor.api";
import {
  CriarTutorEntrada,
  CriarTutorSaida,
} from "../../api/tutor/tutor.types";
import { AxiosError } from "axios";
import {
  EnviarHistoricoEscolarEntrada,
  EnviarHistoricoEscolarSaida,
} from "../../api/student/student.types";
import { enviarHistoricoEscolar } from "../../api/student/student.api";

export const SignUpTutorView: FC = () => {
  const navegar = useNavigate();
  const { state, dispatch } = useSignUp();

  const { data: disciplinas } = useQuery({
    queryKey: ["disciplinas", state.campos.curso],
    queryFn: () => obterDisciplinas({ id_curso: state.campos.curso! }),
  });

  const { data: interesses } = useQuery({
    queryKey: ["interesses"],
    queryFn: () => obterInteresses(),
  });

  const { mutateAsync: criarTutorAsync, isPending } = useMutation<
    CriarTutorSaida,
    AxiosError,
    CriarTutorEntrada
  >({
    mutationKey: ["criar-tutor"],
    mutationFn: (entrada) => criarTutor(entrada),
  });

  const { mutateAsync: enviarHistoricoAsync } = useMutation<
    EnviarHistoricoEscolarSaida,
    AxiosError,
    EnviarHistoricoEscolarEntrada
  >({
    mutationKey: ["enviar-historico"],
    mutationFn: (entrada) => enviarHistoricoEscolar(entrada),
  });

  const continuar = async () => {
    // Validar

    try {
      await criarTutorAsync({
        id_curso: state.campos.curso!,
        id_instituicao_ensino: state.campos.instituicaoDeEnsino!,
        prontuario: state.campos.prontuario,
        interesses_apto: "",
        disciplinas_apto: "",
      });

      await enviarHistoricoAsync({
        file: state.campos.historicoFile!,
      });

      navegar("/cadastrar/sucesso", {
        state: { perfil: state.campos.perfil },
        replace: true,
      });
    } catch (error) {}
  };

  return (
    <Content maxWidth="400px">
      <Typography
        variant="h5"
        fontWeight="bold"
        letterSpacing={1.1}
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="50"
      >
        Preencha sua candidatura para Tutor
      </Typography>

      <Dropzone
        accept={{
          "application/pdf": [".pdf"],
        }}
        invalidText="Formato de arquivo inválido"
        dropzoneHoverText="Solte o PDF do seu histórico escolar aqui"
        dropzoneText="Arraste e solte ou clique e selecione o PDF do seu histórico escolar"
        maxFiles={1}
        onDrop={(files) => {
          const file = files[0];

          if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              if (typeof reader.result === "string") {
                dispatch({
                  type: "DEFINIR_HISTORICO_BASE64",
                  value: reader.result,
                });

                dispatch({ type: "DEFINIR_HISTORICO_FILE", value: file });
              }
            };
            reader.readAsDataURL(file);
          }
        }}
      />

      <Autocomplete
        multiple
        id="disciplinas"
        options={disciplinas?.results || []}
        getOptionLabel={(option) => option.nome}
        value={state.campos.disciplinas}
        onChange={(_, value) =>
          dispatch({ type: "DEFINIR_DISCIPLINAS", disciplinas: value })
        }
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Disciplinas"
            helperText="Selecione as disciplinas para ministrar em tutorias"
            required
          />
        )}
      />

      <Autocomplete
        multiple
        id="interesses"
        options={interesses?.results || []}
        getOptionLabel={(option) => option.nome}
        filterSelectedOptions
        value={state.campos.interesses}
        onChange={(_, value) =>
          dispatch({ type: "DEFINIR_INTERESSES", interesses: value })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Interesses"
            helperText="Selecione os interesses para ministrar em tutorias"
            required
          />
        )}
      />

      <TextField
        type="text"
        variant="standard"
        multiline
        rows={5}
        fullWidth
        label="Justificativa"
        helperText="Justifique sua candidatura para Tutor"
        required
        value={state.campos.justificativa}
        onChange={(e) =>
          dispatch({
            type: "DEFINIR_JUSTIFICATIVA",
            justificativa: e.target.value,
          })
        }
      />

      <BackAndContinue
        carregando={isPending}
        voltar={() => navegar(-1)}
        continuar={continuar}
      />
    </Content>
  );
};
