import { Language as LanguageIcon, Logout } from "@mui/icons-material";
import {
  Avatar,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { FC } from "react";
import { Fragment } from "react/jsx-runtime";
import { obterPerfil } from "../../api/student/student.api";
import { queryClient } from "../../clients/query-client";
import { obterTokenDeAcesso, removerTokens } from "../../utils/auth";
import { eTutorado } from "../../utils/verify-profile";
import { LanguageMenu } from "../language-menu/language-menu.component";
import { useNavigate } from "react-router-dom";

export const Profile: FC = () => {
  const navegar = useNavigate();

  const { data: obterPerfilSaida } = useQuery({
    queryKey: ["perfil"],
    queryFn: () => obterPerfil(),
    enabled: obterTokenDeAcesso() !== null,
  });

  const fotoPerfil = `https://tutorall.com.br/arquivos/fotos-perfil/${obterPerfilSaida?.usuario.id}.jpg`;

  const sair = () => {
    removerTokens();
    navegar("/");
    queryClient.clear();
  };

  return (
    <PopupState variant="popover" popupId="profile-menu">
      {(popupState) => (
        <Fragment>
          <IconButton
            size="small"
            aria-label="Conta do usuário atual"
            aria-controls="profile"
            aria-haspopup="true"
            color="inherit"
            {...bindTrigger(popupState)}
          >
            <Avatar sx={{ width: 32, height: 32 }} src={fotoPerfil} />
          </IconButton>

          <Menu
            id="profile"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            {...bindMenu(popupState)}
          >
            <ListItem dense>
              <ListItemAvatar>
                <Avatar src={fotoPerfil} />
              </ListItemAvatar>
              <ListItemText
                primary={obterPerfilSaida?.usuario.nome}
                secondary={obterPerfilSaida?.usuario.email}
              />
            </ListItem>
            <ListItem dense>
              <ListItemText
                primary="Instituição de ensino"
                secondary={obterPerfilSaida?.instituicao_ensino.sigla}
              />
            </ListItem>
            <ListItem dense>
              <ListItemText
                primary="Perfil"
                secondary={eTutorado(obterPerfilSaida) ? "Tutorado" : "Tutor"}
              />
            </ListItem>
            <Divider />

            <PopupState variant="popover" popupId="language-menu">
              {(popupState) => (
                <Fragment>
                  <MenuItem {...bindTrigger(popupState)}>
                    <ListItemIcon>
                      <LanguageIcon fontSize="small" />
                    </ListItemIcon>
                    Alterar idioma
                  </MenuItem>
                  <LanguageMenu popupState={popupState} />
                </Fragment>
              )}
            </PopupState>

            <MenuItem onClick={sair}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Sair
            </MenuItem>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  );
};
