import { Tutor } from "./types";

export const tutores: Tutor[] = [
    {
        id: "1",
        nome: "Roberta Silva",
        fotoPerfil:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        notaMedia: 3.9,
        quantidadeTutorias: 29,
    },
    {
        id: "2",
        nome: "Maria Silva",
        fotoPerfil:
            "https://images.unsplash.com/photo-1531123897727-8f129e1688ce?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        notaMedia: 3.6,
        quantidadeTutorias: 31,
    },
    {
        id: "3",
        nome: "Eduardo Pereira",
        fotoPerfil:
            "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        notaMedia: 3.0,
        quantidadeTutorias: 1,
    },
    {
        id: "4",
        nome: "Leila Pereira",
        fotoPerfil:
            "https://images.unsplash.com/photo-1517841905240-472988babdf9?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        notaMedia: 2.7,
        quantidadeTutorias: 8,
    },
];