import { IconButton, styled } from "@mui/material";

const Wrapper = styled(IconButton)`
  top: 10px;
  right: 10px;
  position: absolute;
`;

export const ModalCloseButtonStyles = {
    Wrapper
}