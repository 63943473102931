import { Box, styled } from "@mui/material";

export const Background = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
`;

export const Main = styled("main")`
  display: flex;
  flex: 1;
`;

export const LeftContent = styled("aside")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  flex: 1;
  gap: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-y: scroll;

  max-height: calc(100vh - 70px);

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 60%;
  }
`;

export const RightContent = styled("aside")`
  flex: 1;
  background: linear-gradient(to right, #c38cff, #8c62e0);

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SelectPhoto = styled("div")`
  display: flex;
  gap: 16px;
  user-select: none;
  cursor: pointer;
`;

export const Instructions = styled("div")`
  display: flex;
  flex-direction: column;
`;