import axios from "axios"
import { obterDados } from "../common/axios-methods"
import { BASE_URL } from "../common/base-url"
import { ObterInstituicoesEntrada, ObterInstituicoesSaida } from "./institution.types"

const instituicaoAPI = axios.create({
    baseURL: `${BASE_URL}/instituicao`
})

export const obterInstituicoes = (entrada?: ObterInstituicoesEntrada): Promise<ObterInstituicoesSaida> => {
    return instituicaoAPI.get('/', { params: entrada }).then(obterDados)
}