import axios from "axios";
import { BASE_URL } from "../common/base-url";
import { ObterDisciplinasEntrada, ObterDisciplinasSaida } from "./subject.types";
import { obterDados } from "../common/axios-methods";

const disciplinaAPI = axios.create({
    baseURL: `${BASE_URL}/disciplina`
})

export const obterDisciplinas = (entrada: ObterDisciplinasEntrada): Promise<ObterDisciplinasSaida> => {
    return disciplinaAPI.get('/', {
        params: entrada
    }).then(obterDados)
}