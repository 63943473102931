import { Box, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { CardTutor } from "../../components/card-tutor/card-tutor.component";
import { Footer } from "../../components/footer/footer.component";
import { tutores } from "../../constants";
import { HomeStyles } from "./home.styles";

export const HomePage = () => {
  const navegar = useNavigate();
  const logado = false; // Verificar se esta logado ou não

  const agendarTutoria = () => {
    navegar("/auth/login");
  };

  if (logado) {
    return <Navigate to="/painel-de-controle" replace />;
  }

  return (
    <Stack direction="column" spacing="2em">
      <Box
        component="section"
        height="50vh"
        bgcolor="#8c64e3"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="md">
          <Typography
            color="#f8f8ff"
            component="h1"
            variant="h3"
            fontWeight="bold"
            textAlign="center"
          >
            A plataforma de tutoria para todos na sua instituição de ensino
            superior!
          </Typography>
        </Container>
      </Box>

      <HomeStyles.Main>
        <Container>
          <Grid container spacing="16px">
            {tutores.map((tutor) => {
              return (
                <Grid
                  key={tutor.id}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  data-aos="flip-left"
                  data-aos-duration="2000"
                >
                  <CardTutor tutor={tutor} agendarTutoria={agendarTutoria} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </HomeStyles.Main>
      <Footer />
      <Outlet />
    </Stack>
  );
};
